import { baseImagePath } from "../../utility/utility";

const Banner = () => {
  return (
    <section className="banner-section" id="banner">
      <div className="container">
        <div className="banner-wrapper shape-a">
          <div className="row gy-5 align-items-center">
            <div className="col-lg-7 col-12">
              <div className="banner-content">
                <h1>Bitslab Bulk Marketing</h1>
                <p>
                  Bulk WhatsApp messaging service! If you're looking to reach a
                  wide audience with your promotional or informational messages,
                  you've come to the right place.
                </p>
                <a href="#pricing" className="lab-btn">
                  <span>Buy Now</span>{" "}
                </a>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="banner-image">
                <img src={baseImagePath("banner/01.png")} alt="banner-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
