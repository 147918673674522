import Footer from "./Footer";
import Header from "./Header";

const Layout = (props) => {
  return (
    <section className="main-wrapper">
      <Header />
      <div className="main-content-wrapper">{props.children}</div>
      <Footer />
    </section>
  );
};

export default Layout;
