const PrivacyPolicy = () => {
  return (
    <section className="doc-section-wrapper about-section padding-tb">
      <div className="container">
        <div className="section-header">
          <h2>Privacy Policy</h2>
        </div>
        <div className="section-wrapper">
          <div className="privacy-item">
            <h4>1. Introduction</h4>
            <p>
              Welcome to Bitslab Bulk Marketing! This Privacy Policy governs the
              privacy and security of the users of our software and website.
              This policy outlines the types of personal information we collect,
              how we use it, and the choices users have regarding their data.
            </p>
          </div>
          <div className="privacy-item">
            <h4>2. Information We Collect</h4>
            <p>
              a. Personal Information: When you use our WhatsApp bulk marketing
              software and website, we may collect the following personal
              information:
              <br />
              <br />
              Name
              <br />
              Email address
              <br />
              Phone number
              <br />
              Company name (if applicable)
              <br />
              Billing information (if applicable)
              <br />
              Any other information you voluntarily provide to us
              <br />
              <br />
              b. Usage Data: We may collect non-personal information about your
              interaction with our software and website. This may include:
              <br />
              <br />
              IP address
              <br />
              Device information
              <br />
              Browser type
              <br />
              Pages visited
              <br />
              Clicks
              <br />
              referring URL
              <br />
              Log data
            </p>
          </div>
          <div className="privacy-item">
            <h4>3. How We Use Your Information</h4>
            <p>
              a. We use the personal information you provide to us for the
              following purposes:
              <br />
              <br />
              To register and manage your account.
              <br />
              To process and fulfill your orders and requests.
              <br />
              To provide customer support and respond to your inquiries.
              <br />
              To send you promotional and marketing materials (only if you have
              given explicit consent).
              <br />
              To improve our software and website based on user feedback.
              <br />
              b. We may use non-personal information for analytical purposes to
              understand how users interact with our software and website, to
              improve our services, and for marketing purposes.
              <br />
            </p>
          </div>
          <div className="privacy-item">
            <h4>4. Data Security</h4>
            <p>
              We take the security of your data seriously and implement
              reasonable measures to protect it from unauthorized access,
              alteration, disclosure, or destruction. However, please understand
              that no method of transmission over the internet or electronic
              storage is 100% secure, and we cannot guarantee absolute security.
            </p>
          </div>
          <div className="privacy-item">
            <h4>5. Sharing of Information</h4>
            <p>
              We may share your personal information in the following
              circumstances:
              <br />
              <br />
              With service providers and third-party processors who assist us in
              operating our software and website and providing services to you.
              With law enforcement, government officials, or other third parties
              when required by law or to protect our rights, property, or
              safety, or the rights, property, or safety of others. <br />
              In connection with a business transaction, such as a merger,
              acquisition, or sale of assets.
            </p>
          </div>
          <div className="privacy-item">
            <h4>6. Your Choices</h4>
            <p>
              a. You can access, update, or delete your personal information by
              sending the required requests to{" "}
              <a
                className="email-link"
                href="mailto:bitslabtechproducts@gmail.com"
              >
                bitslabtechproducts@gmail.com
              </a>
              .
              <br />
              b. You can unsubscribe from marketing communications at any time
              by sending the required request to{" "}
              <a
                className="email-link"
                href="mailto:bitslabtechproducts@gmail.com"
              >
                bitslabtechproducts@gmail.com
              </a>
              .
            </p>
          </div>
          <div className="privacy-item">
            <h4>7. Cookies and Similar Technologies</h4>
            <p>
              We may use cookies and similar technologies to enhance your user
              experience and collect usage data. You can control cookies through
              your browser settings.
            </p>
          </div>
          <div className="privacy-item">
            <h4>8. Third-Party Links</h4>
            <p>
              Our software and website may contain links to third-party
              websites. We are not responsible for the privacy practices or
              content of these websites. Please review their respective privacy
              policies.
            </p>
          </div>
          <div className="privacy-item">
            <h4>9. Children's Privacy</h4>
            <p>
              Our software and website are not intended for use by individuals
              under the age of 13 years. We do not knowingly collect personal
              information from children under this age. If you believe we have
              inadvertently collected such information, please contact us
              immediately.
            </p>
          </div>
          <div className="privacy-item">
            <h4>10. Changes to this Privacy Policy</h4>
            <p>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. We will notify you of any material changes by
              posting the updated policy on our website or via email.
            </p>
          </div>
          <div className="privacy-item">
            <h4>11. Contact Us</h4>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at <br />
              Email:{" "}
              <a
                className="email-link"
                href="mailto:bitslabtechproducts@gmail.com"
              >
                bitslabtechproducts@gmail.com
              </a>
              <br />
              Phone:{" "}
              <a className="phone-link" href="tel:+919150222512">
                +919150222512
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
