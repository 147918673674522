import { URL_TYPES } from "../../utility/constant";

export const pricingData = [
  {
    planTitle: "Standard Plan",
    planDesc: (
      <>
        <p>One year license (single pc)</p>
        <p>Launch Offer: 1 Month additional license free</p>
      </>
    ),
    planFeaturesList: [
      "Individual bulk messaging",
      "Group bulk messaging",
      "Send dynamic messages",
      "Add Attachments (images, video, pdf)",
      "Anti-Ban feature",
    ],
    price: "₹7999",
    strikePrice: "₹9999",
    url: `https://wa.me/919150222512?text=I'm highly interested in buying your standard plan! It aligns perfectly with my needs, and I'm excited to proceed. Kindly share more details at your earliest convenience. Thank you!`,
    urlType: URL_TYPES.EXTERNAL,
  },
  {
    planTitle: "Trial Plan",
    planDesc: (
      <>
        <p>One month license (single pc)</p>
        <p></p>
      </>
    ),
    planFeaturesList: [
      "All features included in standard plan",
      "Export contacts from group",
      "Export all your contacts list",
      "Google business data scrapping",
      "Auto-Responder Bot",
      "Export active members from group",
      "Bulk group generator",
      "6+ more premium features included",
      "Installation & Activation assistance",
    ],
    price: "949",
    strikePrice: "₹1499",
    url: `https://wa.me/919150222512?text=I'm highly interested in buying your trial plan! It aligns perfectly with my needs, and I'm excited to proceed. Kindly share more details at your earliest convenience. Thank you!`,
    urlType: URL_TYPES.EXTERNAL,
  },
  {
    planTitle: "Premium Plan",
    planDesc: (
      <>
        <p>One year license (single pc)</p>
        <p>Launch Offer: 3 Month additional license free</p>
      </>
    ),
    planFeaturesList: [
      "All features included in standard plan",
      "Export contacts from group",
      "Export all your contacts list",
      "Google business data scrapping",
      "Auto-Responder Bot",
      "Export active members from group",
      "Bulk group generator",
      "6+ more premium features included",
      "Installation & Activation assistance",
    ],
    price: "₹14999",
    strikePrice: "₹24999",
    url: `https://wa.me/919150222512?text=I'm highly interested in buying your premium plan! It aligns perfectly with my needs, and I'm excited to proceed. Kindly share more details at your earliest convenience. Thank you!`,
    urlType: URL_TYPES.EXTERNAL,
  },
];
