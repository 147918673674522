import { NavLink } from "react-router-dom";
import { baseImagePath } from "../../utility/utility";
import { routes } from "../../utility/constant";

const Footer = () => {
  return (
    <footer
      className="footer-section"
      style={{
        background: `#00163f`,
      }}
    >
      <div className="footer-top">
        <div className="container">
          <div className="row g-3 justify-content-center g-lg-0">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="footer-top-item lab-item">
                <div className="lab-inner">
                  <div className="lab-thumb">
                    <img
                      src={baseImagePath("footer/footer-top/01.png")}
                      alt="Phone-icon"
                    />
                  </div>
                  <div className="lab-content">
                    <a href="tel:+919150222512">
                      Phone Number : +91 91502 22512
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="footer-top-item lab-item">
                <div className="lab-inner">
                  <div className="lab-thumb">
                    <img
                      src={baseImagePath("footer/footer-top/02.png")}
                      alt="email-icon"
                    />
                  </div>
                  <div className="lab-content">
                    <a href="mailto:bitslabtech@gmail.com">
                      Email : bitslabtech@gmail.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="footer-top-item lab-item">
                <div className="lab-inner">
                  <div className="lab-thumb">
                    <img
                      src={baseImagePath("footer/footer-top/03.png")}
                      alt="location-icon"
                    />
                  </div>
                  <div className="lab-content">
                    <span>Address : Chennai - 01, Tamil Nadu</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-top">
        <div className="container">
          <div className="row g-3 justify-content-center g-lg-0">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="footer-top-item lab-item custom-footer-top-item">
                <div className="lab-inner">
                  <div className="lab-content">
                    <NavLink className={"footer-item-link"} to={routes.PRIVACY_POLICY}>Privacy Policy</NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="footer-top-item lab-item custom-footer-top-item">
                <div className="lab-inner">
                  <div className="lab-content">
                    <NavLink className={"footer-item-link"} to={routes.TERMS_AND_CONDITIONS}>
                      Terms & Conditions
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="footer-top-item lab-item custom-footer-top-item">
                <div className="lab-inner">
                  <div className="lab-content">
                    <NavLink className={"footer-item-link"} to={routes.TERMS_OF_USE}>Terms Of Use</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footer-middle padding-tb">
        <div className="container">
          <div className="row shape-c">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="footer-middle-item-wrapper">
                <div className="footer-middle-item mb-5 mb-lg-0">
                  <div className="fm-item-title">
                    <h5>About Haldaa</h5>
                  </div>
                  <div className="fm-item-content">
                    <p className="mb-30">
                      Energistica coordinate highly eficient procesr improvement
                      viaing awesome
                    </p>
                    <img
                      className="rounded footer-abt-img"
                      src={baseImagePath("footer/footer-middle/01.jpg")}
                      alt="about-img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="footer-middle-item-wrapper">
                <div className="footer-middle-item mb-5 mb-lg-0">
                  <div className="fm-item-title">
                    <h5>our Recent news</h5>
                  </div>
                  <div className="fm-item-content">
                    <div className="fm-item-widget lab-item">
                      <div className="lab-inner">
                        <div className="lab-thumb">
                          <a href="#">
                            {" "}
                            <img
                              src={baseImagePath("footer/footer-middle/02.jpg")}
                              alt="footer-widget-img"
                            />
                          </a>
                        </div>
                        <div className="lab-content">
                          <h6>
                            <a href="#">
                              Evisculate Economicy Sound Technologies Before.
                            </a>
                          </h6>
                          <p>July 23, 2023</p>
                        </div>
                      </div>
                    </div>
                    <div className="fm-item-widget lab-item">
                      <div className="lab-inner">
                        <div className="lab-thumb">
                          <a href="#">
                            <img
                              src={baseImagePath("footer/footer-middle/03.jpg")}
                              alt="footer-widget-img"
                            />
                          </a>
                        </div>
                        <div className="lab-content">
                          <h6>
                            <a href="#">
                              Globally initiate Global Niche Awesome Markets
                              For.
                            </a>
                          </h6>
                          <p>December 23, 2023</p>
                        </div>
                      </div>
                    </div>
                    <div className="fm-item-widget lab-item">
                      <div className="lab-inner">
                        <div className="lab-thumb">
                          <a href="#">
                            <img
                              src={baseImagePath("footer/footer-middle/04.jpg")}
                              alt="footer-widget-img"
                            />
                          </a>
                        </div>
                        <div className="lab-content">
                          <h6>
                            <a href="#">
                              Authorita Underwhni Tactica Growth Stratege Create{" "}
                            </a>
                          </h6>
                          <p>July 29, 2023</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="footer-middle-item-wrapper">
                <div className="footer-middle-item-3 mb-5 mb-lg-0">
                  <div className="fm-item-title">
                    <h5>OUR NEWSLETTER</h5>
                  </div>
                  <div className="fm-item-content">
                    <p>
                      Haldaa is a event organization supported by community
                      leaders
                    </p>
                    <form>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter email"
                        />
                      </div>
                      <button type="submit" className="lab-btn">
                        Send Massage <i className="icofont-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-bottom-content text-center">
                <p>
                  © {new Date().getFullYear()} Bitslab Technologies - Template
                  By{" "}
                  <a href="https://webspidar.com/" className="template-by">
                    {" "}
                    Webspidar
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
