// import { documentationList } from "./Documentation.data";
import { useEffect, useState } from "react";
import "./Documentation.css";
import $ from "jquery";
import { documentationList } from "./Documentation.data";
import { baseImagePath } from "../../utility/utility";
import NextPrev from "../NextPrev/NextPrev";
import YoutubeEmbed from "./YoutubeEmbed";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../utility/constant";

const Documentation = () => {
  const [selectedDoc, setSelectedDoc] = useState(documentationList[0].id);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const updateSelectedDoc = (currentPageIndex) => {
    setSelectedDoc(documentationList[currentPageIndex].id);
    const index = documentationList.findIndex(
      (i) => i.id === documentationList[currentPageIndex].id
    );
    navigate(routes.DOCS, {
      state: {
        selectedDoc: documentationList[currentPageIndex].id,
        currentPageIndex: index,
      },
    });
  };
  useEffect(() => {
    if (location?.state?.selectedDoc) {
      setSelectedDoc(location.state.selectedDoc);
      $('.wrap.ani').click()
    }
    if (location?.state?.currentPageIndex) {
      setCurrentPageIndex(location.state.currentPageIndex);
    }
  }, [location.state?.currentPageIndex, location.state?.selectedDoc]);
  return (
    <section className="doc-section-wrapper about-section padding-tb">
      <div className="doc-content-wrapper-section">
        <div id="wrapper">
          <div className="container">
            <div className="floating-section-wrapper">
              <div className="floating-content-wrapper popup-ani open">
                <ul id="header">
                  {documentationList.map((i, index) => {
                    return (
                      <li
                        className={i.id === selectedDoc ? "active" : ""}
                        key={i.id}
                        onClick={() => {
                          navigate(routes.DOCS, {
                            state: {
                              selectedDoc: i.id,
                              currentPageIndex: index,
                            },
                          });
                          setSelectedDoc(i.id);
                          setCurrentPageIndex(index);
                          $(window).scrollTop(0);
                          $(".floating-btn-wrapper .wrap").toggleClass("ani");
                          $(".floating-content-wrapper").toggleClass("open");
                          $(".img-fab.img").toggleClass("close");
                        }}
                      >
                        <button
                          id={`doc_${i.id}_link`}
                          style={{ WebkitTapHighlightColor: "rgba0, 0, 0, 0." }}
                        >
                          <i className="fa fa-hand-o-right"></i> {i.title}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div
                className="floating-btn-wrapper"
                onClick={() => {
                  $(".floating-btn-wrapper .wrap").toggleClass("ani");
                  $(".floating-content-wrapper").toggleClass("open");
                  $(".img-fab.img").toggleClass("close");
                }}
              >
                <div className="wrap ani">
                  <img
                    src={baseImagePath("documentation/doc.png")}
                    alt="doc-float"
                    className="doc-floating-img"
                  />
                  <div className="img-fab img"></div>
                </div>
              </div>
            </div>
            {documentationList
              .filter((i) => i.id === selectedDoc)
              .map((i) => {
                return (
                  <section key={i.id}>
                    <div className="container">
                      {" "}
                      <br />
                      <h4>{i.title}</h4>
                      <div className="doc-content-wrapper">
                        {i.steps.map((j, index) => {
                          return (
                            <div key={`${i.id}_${index}`}>
                              {j.description && (
                                <p>
                                  {index + 1}. {j.description}
                                </p>
                              )}
                              {j.url && (
                                <>
                                  <a
                                    href={j.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {j.urlText}
                                  </a>
                                  <br />
                                  <br />
                                </>
                              )}
                              {j.image && <img alt={j.title} src={j.image} />}
                              {j.videoUrl && (
                                <YoutubeEmbed
                                  url={j.videoUrl}
                                  title={i.title}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </section>
                );
              })}
            <NextPrev
              data={documentationList}
              setCurrentPageIndex={setCurrentPageIndex}
              currentPageIndex={currentPageIndex}
              updateSelectedDoc={updateSelectedDoc}
            />
            {/* <!-- Footer --> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Documentation;
