import { baseImagePath } from "../../utility/utility";

export const documentationList = [
  {
    id: 1,
    title: "Requirements",
    steps: [
      {
        description:
          "Download .Net Framework 4.6.1 runtime setup from below url",
        url: "https://dotnet.microsoft.com/en-us/download/dotnet-framework/net461",
        urlText: "Click Here to Download .Net Framework",
        image: baseImagePath("documentation/requirements/dotnet.png"),
        hyperLinkDescription: false,
        videoUrl: "",
      },
      {
        description: "Download and Install Google Chrome from below url",
        url: "https://www.google.com/chrome/",
        hyperLinkDescription: false,
        urlText: "Click Here to Download Google Chrome",
        image: baseImagePath("documentation/requirements/chrome.png"),
        videoUrl: "",
      },
    ],
  },
  {
    id: 2,
    title: "How to install BBM software?",
    steps: [
      {
        description:
          "Once you have purchased and received the software follow the instructions for installation from below url ",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/IpiYP1HuuXo",
      },
    ],
  },
  {
    id: 3,
    title: "How to activate BBM software?",
    steps: [
      {
        description:
          "Open the BBM software and copy the activation code from the software",
        url: "",
        urlText: "",
        image: baseImagePath("documentation/activation/1.jpg"),
        videoUrl: "",
      },
      {
        description:
          "Send the activation code to us, we will verify your purchase and provide you the license key.",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "",
      },
      {
        description:
          "Once you get the license key paste that in the software as shown in below image",
        url: "",
        urlText: "",
        image: baseImagePath("documentation/activation/2.jpg"),
        videoUrl: "",
      },
    ],
  },
  {
    id: 4,
    title: "How to send individual bulk messages?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/27e5zA0L39k",
      },
    ],
  },
  {
    id: 5,
    title: "How to use key marker for sending dynamic content in messages?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/xd054wcU8j0",
      },
    ],
  },
  {
    id: 6,
    title: "How to add attachments(images, video, documents) in messages?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/bX6OTWXW_cA",
      },
    ],
  },
  {
    id: 7,
    title: "How to forward messages to multiple groups?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/FW-PoByFVIc",
      },
    ],
  },
  {
    id: 8,
    title: "How to grab WhatsApp group link from web?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/roupElM8mFs",
      },
    ],
  },
  {
    id: 9,
    title: "How to configure auto responder bot?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/QGAnFTxr4AQ",
      },
    ],
  },
  {
    id: 10,
    title: "How to send dynamic parametarized messages?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/GtMbThhjcTA",
      },
    ],
  },
  {
    id: 11,
    title: "How to export your complete saved contacts list or list of groups?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/lJUO6A1fSI0",
      },
    ],
  },
  {
    id: 12,
    title:
      "How to filter out list of contacts having WhatsApp account from a list of N number of contacts from excel file?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/iMY2WwrUUuo",
      },
    ],
  },
  {
    id: 13,
    title: "How to export the list of active group members?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/vz-ewwutvXg",
      },
    ],
  },
  {
    id: 14,
    title: "How to export the complete details of a business from google maps?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/Sc5b1okoZTI",
      },
    ],
  },
  {
    id: 15,
    title: "How to add multiple members to a WhatsApp group in bulk?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/5ewXbeaUOUs",
      },
    ],
  },
  {
    id: 16,
    title: "How to find groups through the internet?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/Agk4lRlvDSo",
      },
    ],
  },
  {
    id: 17,
    title: "How to generate multiple groups in one click?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/UztxUVrHHbM",
      },
    ],
  },
  {
    id: 18,
    title: "How to export all members from groups in one click?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/8BSHezkEYFM",
      },
    ],
    featureTitle: "Grab Group Members",
  },
  {
    id: 19,
    title:
      "How to export all members with you have initiated any kind of conversations in one click?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/vkdlo8V1vOk",
      },
    ],
  },
  {
    id: 20,
    title: "How to join multiple groups in one click?",
    steps: [
      {
        description: "",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "https://www.youtube.com/embed/bLmDOlJwO5E",
      },
    ],
  },
  {
    id: 20,
    title: "How to export all your google contacts?",
    steps: [
      {
        description:
          "If you have multiple contacts stored on your phone and not in your google account you can export all your contacts and our premium feature google contact csv generator will generate a csv file for you which can be uploaded in google contacts and all your contacts will be saved in your google account",
        url: "",
        urlText: "",
        image: "",
        videoUrl: "",
      },
    ],
  },
];
