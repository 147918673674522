// import { baseImagePath } from "../../utility/utility";

const AboutPremiumVideo = () => {
  return (
    <section className="about-section padding-tb padding-b" id="about">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/* <div className="about-image">
              <img src={baseImagePath("about/01.jpg")} alt="about img" />
              <a
                href="https://www.youtube.com/embed/SP3yyrboTno"
                className="play-btn"
                data-rel="lightcase"
              >
                <i className="icofont-ui-play"></i>
                <span className="pluse_2"></span>
              </a>
            </div> */}
          </div>
          <div className="col-lg-10">
            <div className="section-header text-center">
              <h2>Product Overview</h2>
              {/* <p>About The Bitslab Bulk Marketing Premium Features</p> */}
            </div>
            <div className="section-wrapper text-center">
              <p>
                Experience the ease of WhatsApp automation. Instantly get group
                members' phone numbers. Extract group links from any browser.
                Set up your personalized auto-responder bot. Grab saved contacts
                to Excel for bulk messaging. Extract comprehensive business
                details from Google. Join multiple groups automatically. Filter
                WhatsApp users from phone number lists. Identify active members
                and export to Excel for bulk messaging. Grab phone numbers from
                your chat list for easy forwarding. Add hundreds of members to
                your group effortlessly. Find and join relevant WhatsApp groups
                effortlessly. Simplify your WhatsApp experience now!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPremiumVideo;
