import AboutPremiumVideo from "../../Components/AboutPremiumVideo/AboutPremiumVideo";
import Banner from "../../Components/Banner/Banner";
import PremiumFeatures from "../../Components/PremiumFeatures/PremiumFeatures";
import Pricing from "../../Components/Pricing/Pricing";

const Home = () => {
  return (
    <div className="home-wrapper">
      <Banner />
      <AboutPremiumVideo />
      <PremiumFeatures />
      <Pricing />
    </div>
  );
};

export default Home;
