export const premiumFeaturesList = [
  {
    title: "Grab Group Members",
    description:
      "Get phone numbers of all members of any group, just at the blink of eye.",
    icon: "",
    docId: 18,
  },
  {
    title: "Grab WhatsApp Group Links from web page",
    description:
      "Extract all the Group links from any internet browser  page you visited.",
    icon: "",
    docId: 8,
  },
  {
    title: "WhatsApp Auto Responder Bot",
    description:
      "Setup your own WhatsApp Auto responder bot. Set your condition and rules and rest our bot is good to handle your clients and enquires.",
    icon: "",
    docId: 9,
  },
  {
    title: "Contact List Grabber",
    description:
      "Grab all your saved contacts in WhatsApp Automatically just at a click to Excel file which can be used to send Bulk messages.",
    icon: 11,
  },
  {
    title: "Google Map Data Extractor",
    description:
      "Extract complete details of a business category from Google business. Want the complete details of all business related to groceries located in any particular area listed on Google business/map? Your just a click away and grab all the details like Address, phone number, reviews counts, Average reviews, websites, etc.",
    icon: "",
    docId: 14,
  },
  {
    title: "Auto Group Joiner",
    description:
      "Got multiple groups links that you want to join (hundreds of them?) No worries our software will join all the groups automatically while you sit back and relax.",
    icon: "",
    docId: 20,
  },
  {
    title: "WhatsApp Number Filter",
    description:
      "Filter WhatsApp users from a list of given phone number. Our software will filter out the non WhatsApp users from your given list of phone numbers.",
    icon: "",
    docId: 12,
  },
  {
    title: "Grab Active Group Members",
    description:
      "Filter out Active WhatsApp members from single/multiple groups all in one go and get it converted to Excel file which can used for sending bulk messages.",
    icon: "",
    docId: 13,
  },
  {
    title: "Grab Chat List",
    description:
      "Grab all the phone numbers from your WhatsApp chat list to Excel file and the same file can be used for bulk forwarding.",
    icon: "",
    docId: 19,
  },
  {
    title: "Bulk Add Group Members",
    description:
      "Creating a group is easy but additing hundreds of members to the group can be hectic work. Just provide our software with the Excel file and it will add all the members to your group automatically while you take a sip of your favourite drink.",
    icon: "",
    docId: 15,
  },
  {
    title: "Group Finder",
    description:
      "Finding new WhatsApp groups is not a easy task. In our software you can just enter the name related to the group you would like to join to (like clothing, shoes, drop shipping,etc) and it will give you list of all the WhatsApp groups related to it and you can directly get added to all those groups automatically one by one.",
    icon: "",
    docId: 16,
  },
  {
    title: "Bulk Group Generator",
    description:
      "Effortlessly create large groups in WhatsApp with the Bulk Group Generator. Save time and energy by quickly generating and adding multiple contacts to your desired group chats.",
    icon: "",
    docId: 17,
  },
  {
    title: "Send Parametarized/Dynamic Content",
    description:
      "You can also send dynamic or parametarized content based on your requirements.",
    icon: "",
    docId: 10,
  },
  {
    title: "Google Contacts CSV Generator",
    description:
      "You can also send dynamic or parametarized content based on your requirements.",
    icon: "",
    docId: 21,
  },
];
