import { NavLink } from "react-router-dom";
import { URL_TYPES } from "../../utility/constant";
import { pricingData } from "./Pricing.data";

const Pricing = () => {
  return (
    <section className="pricing-plan-section padding-tb padding-b" id="pricing">
      <div className="container">
        <div className="section-header">
          <h2>Our Pricing Plan</h2>
          <p>Choose Your Plan That Fits You Best</p>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center">
            {pricingData.map((i, index) => {
              return (
                <div key={index} className="col-xl-4 col-md-6 col-12">
                  <div className="pricing-item">
                    <div className="pricing-inner">
                      <div className="pricing-header">
                        <h3>{i.planTitle}</h3>
                        {i.planDesc}
                      </div>
                      <div className="pricing-content">
                        <ul className="facilites">
                          {i.planFeaturesList.map((j) => {
                            return (
                              <li key={j} className="facility-item">
                                <span>
                                  <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                  ></i>
                                </span>{" "}
                                {j}
                              </li>
                            );
                          })}
                        </ul>
                        <div className="get-ticket">
                          {i.urlType === URL_TYPES.INTERNAL ? (
                            <NavLink to={i.url}>
                              <ul>
                                <li className="vat">
                                  {i.price ? (
                                    <>
                                      <h4>{i.price}</h4>
                                      <p
                                        style={{
                                          textDecoration: "line-through",
                                          fontWeight: 700
                                        }}
                                      >
                                        {i.strikePrice}
                                      </p>
                                      &nbsp;
                                      <p>ex. GST</p>
                                    </>
                                  ) : (
                                    <p>Enquire Now</p>
                                  )}
                                </li>
                                <li className="icon">
                                  <i
                                    className="fa fa-arrow-right"
                                    aria-hidden="true"
                                  ></i>
                                </li>
                                <li className="ticket-text">
                                  {i.price ? <p>Buy Now</p> : <p>Contact Us</p>}
                                </li>
                              </ul>
                            </NavLink>
                          ) : (
                            <a target="_blank" rel="noreferrer" href={i.url}>
                              <ul>
                                <li className="vat">
                                  {i.price ? (
                                    <>
                                      <h4>{i.price}</h4>
                                      <p
                                        style={{
                                          textDecoration: "line-through",
                                          fontWeight: 700

                                        }}
                                      >
                                        {i.strikePrice}
                                      </p>
                                      &nbsp;
                                      <p>ex. GST</p>
                                    </>
                                  ) : (
                                    <p>Enquire Now</p>
                                  )}
                                </li>
                                <li className="icon">
                                  <i
                                    className="fa fa-arrow-right"
                                    aria-hidden="true"
                                  ></i>
                                </li>
                                <li className="ticket-text">
                                  {i.price ? <p>Buy Now</p> : <p>Contact Us</p>}
                                </li>
                              </ul>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
