import "./NextPrev.css";
import $ from "jquery";

const NextPrev = ({ data, currentPageIndex, setCurrentPageIndex, updateSelectedDoc }) => {
  const nextPageIndex = currentPageIndex + 1;
  const prevPageIndex = currentPageIndex - 1;
  const isNextAvail = data[nextPageIndex] ? true : false;
  const isPrevAvail = data[prevPageIndex] ? true : false;
  return (
    <section className="next-prev-section padding-tb">
      <div className="next-prev-wrapper">
        <div>
          {isPrevAvail && (
            <button
              onClick={() => {
                $(window).scrollTop(0);
                setCurrentPageIndex(prevPageIndex);
                updateSelectedDoc(prevPageIndex)
              }}
              className="lab-btn about-btn-grp"
            >
              <span>Prev - {data[prevPageIndex].title}</span>{" "}
            </button>
          )}
        </div>
        <div>
          {isNextAvail && (
            <button
              onClick={() => {
                $(window).scrollTop(0);
                setCurrentPageIndex(nextPageIndex);
                updateSelectedDoc(nextPageIndex)
              }}
              className="lab-btn about-btn-grp"
            >
              <span>Next - {data[nextPageIndex].title}</span>{" "}
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default NextPrev;
