import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import { routes } from "../utility/constant";
import Layout from "../hoc/Layout/Layout";
import Documentation from "../Components/Documentation/Documentation";
// import Reseller from "../Components/Reseller/Reseller";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "../pages/TermsAndCondition/TermsAndCondition";
import TermsOfUse from "../pages/TermsOfUse/TermsOfUse";

const AppRouter = () => {
  return (
    <Layout>
      <Routes>
        <Route path={routes.ROOT} element={<Home />} />
        <Route path={routes.HOME} element={<Navigate to={routes.ROOT} />} />
        <Route path={routes.DOCS} element={<Documentation />} />
        {/* <Route path={routes.RESELLER} element={<Reseller />} /> */}
        <Route path={routes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route
          path={routes.TERMS_AND_CONDITIONS}
          element={<TermsAndCondition />}
        />
        <Route path={routes.TERMS_OF_USE} element={<TermsOfUse />} />
        <Route path="*" element={<Navigate to={routes.ROOT} />} />
      </Routes>
    </Layout>
  );
};

export default AppRouter;
