import { NavLink, useLocation } from "react-router-dom";
import { routes } from "../../utility/constant";
import { useEffect } from "react";
import $ from "jquery";

const Header = () => {
  // const loadJquery = () => {
  //   $(document).on("click", function (event) {
  //     var clickover = $(event.target);
  //     var _opened = $("#menu-items-wrapper").hasClass("active");
  //     // console.log(_opened, "trueOrFalse")
  //     // console.log(!clickover.hasClass("navbar-toggler"))
  //     debugger
  //     if (_opened === true && !clickover.hasClass("menu-area")) {
  //       if ($("#menu-btn-wrapper").hasClass("active")) {
  //         $("#menu-btn-wrapper").removeClass("active");
  //         $("#menu-items-wrapper").removeClass("active");
  //       }
  //     }
  //   });
  // };

  const closeMenuBar = () => {
    $("#menu-btn-wrapper").removeClass("active");
    $("#menu-items-wrapper").removeClass("active");
  };
  const location = useLocation();
  useEffect(() => {
    // loadJquery();
  }, []);
  return (
    <header className="header-section">
      <div className="header-bottom">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo">
              <NavLink to={routes.HOME}>Bitslab Technologies</NavLink>
            </div>
            <div className="menu-area" id="menu-area">
              <ul className="menu" id="menu-items-wrapper">
                <li
                  onClick={() => {
                    closeMenuBar();
                  }}
                >
                  <NavLink to={routes.ROOT}>Home</NavLink>
                </li>
                <li
                  onClick={() => {
                    closeMenuBar();
                  }}
                >
                  <NavLink to={routes.DOCS}>Documentation</NavLink>
                </li>
                {routes.ROOT.includes(location.pathname) && (
                  <li
                    onClick={() => {
                      closeMenuBar();
                    }}
                  >
                    <a href={routes.PREMIUM_FEATURES}>Premium Features</a>
                  </li>
                )}
                {routes.ROOT.includes(location.pathname) && (
                  <li
                    onClick={() => {
                      closeMenuBar();
                    }}
                  >
                    <a href={routes.PRICING}>Pricing</a>
                  </li>
                )}
                {/* <li>
                  <NavLink to={routes.CONTACT_US}>Contact Us</NavLink>
                </li> */}
              </ul>
              <div className="cart-ticket">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://wa.me/919150222512?text=I'm highly interested in buying your product! It aligns perfectly with my needs, and I'm excited to proceed. Kindly share pricing and purchase details at your earliest convenience. Thank you!`}
                  className="ticket-btn lab-btn"
                >
                  <span>Buy Now</span>
                </a>
                {/* <NavLink to={routes.RESELLER} className="ticket-btn lab-btn-inverse">
                  <span>Become A Reseller</span>
                </NavLink> */}
              </div>

              {/* <!-- toggle icons --> */}
              <div className="header-bar d-lg-none" id="menu-btn-wrapper">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
