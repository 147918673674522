import { useEffect } from "react";
import { withRouter } from "./withRouter";
import $ from "jquery";

const Component = (props) => {
  useEffect(() => {
    console.log("Route Changed");
    $(window).scrollTop(0);
  }, [props.router.location.pathname, props.router.location.search]);
  return null;
};

export default withRouter(Component);
