import { NavLink } from "react-router-dom";
import { premiumFeaturesList } from "./PremiumFeatures.data";
import { routes } from "../../utility/constant";
import { documentationList } from "../Documentation/Documentation.data";

const PremiumFeatures = () => {
  return (
    <section
      className="schedule-section padding-tb padding-b bg-image"
      id="premium-features"
    >
      <div className="container">
        <div className="section-header">
          <h2>Our Premium Features</h2>
          <p>
            Unlock the premium features of our WhatsApp bulk marketing service.
            {/* Experience advanced targeting options and personalized messaging.
            Maximize your marketing efforts with our premium features and take
            your WhatsApp campaigns to new heights.Drive engagement, increase
            conversions, and achieve remarkable results. Upgrade to our premium
            package today and unleash the full potential of WhatsApp bulk
            marketing. */}
          </p>
        </div>
        <div className="section-wrapper shape-b">
          <div className="row gx-4 gy-5">
            <div className="col-lg-6">
              <div className="schedule-left schedule-pack">
                <h5>Premium Features</h5>
                <div className="schedule-list" id="accordionExample">
                  {premiumFeaturesList.map((i, index) => {
                    if (index > 6) {
                      return null;
                    }
                    const docIndex = documentationList.findIndex(
                      (j) => j.id === i.docId
                    );
                    return (
                      <div className="accordion-item" key={index}>
                        <div
                          className="accordion-header"
                          id={`heading${index}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            aria-expanded="false"
                            aria-controls={`collapse${index}`}
                          >
                            <span className="accor-header-inner d-flex flex-wrap align-items-center">
                              {/* <span className="accor-thumb">
                                <img
                                  src={baseImagePath("event/member/01.png")}
                                  alt="speaker"
                                />
                                <span className="child-thumb">
                                  <img
                                    src={baseImagePath("event/member/02.png")}
                                    alt="speaker"
                                  />
                                </span>
                                <span className="child-thumb-2">
                                  <img
                                    src={baseImagePath("event/member/03.png")}
                                    alt="speaker"
                                  />
                                </span>
                              </span> */}
                              <span className="h7">{i.title}</span>
                            </span>
                          </button>
                        </div>
                        <div
                          id={`collapse${index}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`heading${index}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body text-center">
                            <p>{i.description}</p>
                            <NavLink
                              to={routes.DOCS}
                              state={{
                                selectedDoc: i.docId,
                                currentPageIndex: docIndex,
                              }}
                              className="ticket-btn lab-btn-inverse"
                            >
                              <span>Demo</span>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="schedule-left schedule-pack">
                <h5>Premium Features</h5>
                <div className="schedule-list" id="accordionExample">
                  {premiumFeaturesList.map((i, index) => {
                    if (index <= 6) {
                      return null;
                    }
                    const docIndex = documentationList.findIndex(
                      (j) => j.id === i.docId
                    );
                    return (
                      <div className="accordion-item" key={index}>
                        <div
                          className="accordion-header"
                          id={`heading${index}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            aria-expanded="false"
                            aria-controls={`collapse${index}`}
                          >
                            <span className="accor-header-inner d-flex flex-wrap align-items-center">
                              {/* <span className="accor-thumb">
                                <img
                                  src={baseImagePath("event/member/01.png")}
                                  alt="speaker"
                                />
                                <span className="child-thumb">
                                  <img
                                    src={baseImagePath("event/member/02.png")}
                                    alt="speaker"
                                  />
                                </span>
                                <span className="child-thumb-2">
                                  <img
                                    src={baseImagePath("event/member/03.png")}
                                    alt="speaker"
                                  />
                                </span>
                              </span> */}
                              <span className="h7">{i.title}</span>
                            </span>
                          </button>
                        </div>
                        <div
                          id={`collapse${index}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`heading${index}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body text-center">
                            <p>{i.description}</p>
                            <NavLink
                              to={routes.DOCS}
                              state={{
                                selectedDoc: i.docId,
                                currentPageIndex: docIndex,
                              }}
                              className="ticket-btn lab-btn-inverse text-center"
                            >
                              <span>Demo</span>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="schedule-btns text-center mt-5">
            <a href="#!" className="lab-btn">
              Download Schedule
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default PremiumFeatures;
