const TermsOfUse = () => {
  return (
    <section className="doc-section-wrapper about-section padding-tb">
      <div className="container">
        <div className="section-header">
          <h2>Terms Of Use</h2>
        </div>
        <div className="section-wrapper">
          <div className="privacy-item">
            {/* <h4>1. Introduction</h4> */}
            <p>
              Welcome to Bitslab Bulk Marketing! By accessing and using our
              WhatsApp bulk marketing software and website, you agree to be
              bound by the following terms and conditions. If you do not agree
              with these terms, please do not use our software and website.
            </p>
          </div>
          <div className="privacy-item">
            <h4>1. Use of Our Software and Website</h4>
            <p>
              a. You must be of legal age and have the necessary legal capacity
              to use our software and website, or you must have obtained the
              necessary consent from your parent or legal guardian.
              <br />
              b. You agree to use our software and website only for lawful
              purposes and in compliance with all applicable laws and
              regulations.
              <br />
              c. You are responsible for maintaining the confidentiality of your
              account credentials and for all activities that occur under your
              account.
            </p>
          </div>
          <div className="privacy-item">
            <h4>2. Anti-Spam Policy</h4>
            <p>
              a. You agree not to use our WhatsApp bulk marketing software or
              website for spamming or sending unsolicited messages. Spamming
              includes sending messages to individuals who have not given you
              explicit consent to receive such messages.
              <br />
              b. You are solely responsible for ensuring that you have obtained
              the necessary consent from recipients before sending marketing
              messages to them through our software.
              <br />
              c. We reserve the right to suspend or terminate your account and
              access to our software and website if we determine, in our sole
              discretion, that you have violated our anti-spam policy.
            </p>
          </div>
          <div className="privacy-item">
            <h4>3. Intellectual Property Rights</h4>
            <p>
              a. All intellectual property rights in our software, website, and
              any related content (including but not limited to text, graphics,
              images, logos, and software code) are owned by or licensed to us.
              <br />
              b. You are granted a limited, non-exclusive, non-transferable
              license to use our software and website for your personal or
              business purposes in accordance with these Terms of Use.
            </p>
          </div>
          <div className="privacy-item">
            <h4>4. Prohibited Conduct</h4>
            <p>
              a. You agree not to:
              <br />
              Use our software and website in any way that could damage,
              disable, overburden, or impair our servers or networks. Attempt to
              gain unauthorized access to any part of our software, website, or
              any other systems or networks connected to our services.
              <br />
              Reverse engineer, decompile, or disassemble any aspect of our
              software or website.
              <br />
              Use any automated means, including bots or scripts, to interact
              with our software or website unless explicitly permitted by us.
              <br />
              Engage in any activity that interferes with the proper functioning
              of our software or website.
            </p>
          </div>
          <div className="privacy-item">
            <h4>5. Limitation of Liability</h4>
            <p>
              a. To the fullest extent permitted by law, we disclaim all
              warranties, express or implied, regarding the use of our software
              and website. We do not guarantee that our software and website
              will be error-free, secure, or uninterrupted.
              <br />
              b. In no event shall we be liable for any direct, indirect,
              incidental, consequential, or punitive damages arising out of or
              related to the use of our software and website.
            </p>
          </div>
          <div className="privacy-item">
            <h4>6. Indemnification</h4>
            <p>
              You agree to indemnify and hold us harmless from any claims,
              damages, liabilities, and expenses (including attorneys' fees)
              arising out of or related to your use of our software and website
              or any violation of these Terms of Use.
            </p>
          </div>
          <div className="privacy-item">
            <h4>7. Modifications to the Terms of Use</h4>
            <p>
              We may update these Terms of Use from time to time to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. We will notify you of any material changes by
              posting the updated terms on our website or via email.
            </p>
          </div>
          <div className="privacy-item">
            <h4>8. Termination</h4>
            <p>
              We reserve the right to terminate or suspend your access to our
              software and website at any time and for any reason without prior
              notice.
            </p>
          </div>
          <div className="privacy-item">
            <h4>9. Governing Law</h4>
            <p>
              These Terms of Use shall be governed by and construed in
              accordance with the laws of Tamil Nadu - India.
            </p>
          </div>
          <div className="privacy-item">
            <h4>10. Contact Us</h4>
            <p>
              If you have any questions or concerns about these Terms of Use or
              any other matter, please contact us at <br />
              Email:{" "}
              <a
                className="email-link"
                href="mailto:bitslabtechproducts@gmail.com"
              >
                bitslabtechproducts@gmail.com
              </a>
              <br />
              Phone:{" "}
              <a className="phone-link" href="tel:+919150222512">
                +919150222512
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsOfUse;
