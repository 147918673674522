const TermsAndCondition = () => {
  return (
    <section className="doc-section-wrapper about-section padding-tb">
      <div className="container">
        <div className="section-header">
          <h2>Terms And Conditions</h2>
        </div>
        <div className="section-wrapper">
          <div className="privacy-item">
            <h4>Terms and Conditions</h4>
            <p>
              Welcome to Bitslab Bulk marketing! By accessing and using our
              website, you agree to be bound by the following terms and
              conditions. If you do not agree with these terms, please do not
              use our website.
            </p>
          </div>
          <div className="privacy-item">
            <h4>1. Use of Website</h4>
            <p>
              a. You must be of legal age and have the necessary legal capacity
              to use our website, or you must have obtained the necessary
              consent from your parent or legal guardian.
              <br />
              b. You agree to use our website only for lawful purposes and in
              compliance with all applicable laws and regulations.
              <br />
              c. You are responsible for maintaining the confidentiality of your
              account credentials and for all activities that occur under your
              account.
              <br />
            </p>
          </div>
          <div className="privacy-item">
            <h4>2. Intellectual Property Rights</h4>
            <p>
              a. All content on our website, including but not limited to text,
              graphics, images, logos, and software code, is the property of
              Bitslab or its licensors and is protected by
              intellectual property laws.
              <br />
              b. You may access and use the content on our website for personal,
              non-commercial purposes only. Any other use, including
              reproduction, modification, distribution, or republication,
              without our prior written consent, is strictly prohibited.
              <br />
            </p>
          </div>
          <div className="privacy-item">
            <h4>3. Links to Third-Party Websites</h4>
            <p>
              a. Our website may contain links to third-party websites for your
              convenience. We do not endorse, control, or monitor the content on
              these websites, and we are not responsible for their practices or
              policies.
              <br />
              b. If you decide to access any third-party website linked from our
              website, you do so at your own risk, and you should review their
              respective terms and conditions and privacy policies.
            </p>
          </div>
          <div className="privacy-item">
            <h4>4. Disclaimer of Warranties</h4>
            <p>
              a. Our website and its content are provided on an "as is" and "as
              available" basis without warranties of any kind, whether express
              or implied.
              <br />
              b. We do not guarantee that our website will be error-free,
              secure, or uninterrupted. We make no representations or warranties
              regarding the accuracy, completeness, or reliability of the
              content on our website.
            </p>
          </div>
          <div className="privacy-item">
            <h4>5. Limitation of Liability</h4>
            <p>
              a. To the fullest extent permitted by law, we disclaim all
              liability for any direct, indirect, incidental, consequential, or
              punitive damages arising out of or related to the use of our
              website.
              <br />
              b. We shall not be liable for any damages or losses resulting from
              viruses, malware, or other harmful components that may infect your
              computer or other equipment.
            </p>
          </div>
          <div className="privacy-item">
            <h4>6. Indemnification</h4>
            <p>
              You agree to indemnify and hold us harmless from any claims,
              damages, liabilities, and expenses (including attorneys' fees)
              arising out of or related to your use of our website or any
              violation of these Terms and Conditions.
            </p>
          </div>
          <div className="privacy-item">
            <h4>7. Modifications to the Terms and Conditions</h4>
            <p>
              We may update these Terms and Conditions from time to time to
              reflect changes in our practices or for other operational, legal,
              or regulatory reasons. We will notify you of any material changes
              by posting the updated terms on our website.
            </p>
          </div>
          <div className="privacy-item">
            <h4>8. Termination</h4>
            <p>
              We reserve the right to terminate or suspend your access to our
              website at any time and for any reason without prior notice.
            </p>
          </div>
          <div className="privacy-item">
            <h4>9. Governing Law and Jurisdiction</h4>
            <p>
              These Terms and Conditions shall be governed by and construed in
              accordance with the laws of India. Any disputes arising out of or
              related to these Terms and Conditions shall be subject to the
              exclusive jurisdiction of the courts in India.
            </p>
          </div>
          <div className="privacy-item">
            <h4>10. Severability</h4>
            <p>
              If any provision of these Terms and Conditions is found to be
              invalid or unenforceable, the remaining provisions shall remain in
              full force and effect.
            </p>
            <div className="privacy-item">
              <h4>11. Entire Agreement</h4>
              <p>
              These Terms and Conditions constitute the entire agreement between you and Bitslab concerning the subject matter herein and supersedes all prior and contemporaneous agreements.
              </p>
            </div>
            <div className="privacy-item">
              <h4>12. Contact Us</h4>
              <p>If you have any questions or concerns about these Terms and Conditions or any other matter, please contact us at <br/>
              Email:{" "}
              <a
                className="email-link"
                href="mailto:bitslabtechproducts@gmail.com"
              >
                bitslabtechproducts@gmail.com
              </a>
              <br />
              Phone:{" "}
              <a className="phone-link" href="tel:+919150222512">
                +919150222512
              </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsAndCondition;
