import ReactPlayer from "react-player/lazy";

const YoutubeEmbed = (props) => {
  return (
    <div className="youtube-embed-wrapper">
      <ReactPlayer
        url={props.url}
        config={{
          youtube: { playerVars: { cc_load_policy: 1 } },
        }}
        className="h-100 w-100"
      />
    </div>
  );
};

export default YoutubeEmbed;
