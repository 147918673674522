import "./App.css";
import AppRouter from "./ router/ router";
import RouteChangeListener from "./utility/RouteChangeListener";

function App() {
  return (
    <>
      <RouteChangeListener />
      <AppRouter />
    </>
  );
}

export default App;
