export const routes = {
  ROOT: "/",
  HOME: "/home",
  CONTACT_US: "/contact-us",
  DOCS: "/docs",
  PRICING: "/#pricing",
  RESELLER: "/reseller-plan",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_USE: "/terms-of-use",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  PREMIUM_FEATURES: "/#premium-features"
};

export const URL_TYPES = {
  INTERNAL: "internal",
  EXTERNAL: "external",
};
